import React, {useCallback, useEffect, useState} from "react";
import {ActionButton} from "../../support/ActionButton";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../auth/AuthHook";

export const LoginButton = ({onAuthenticationError, onAuthenticationSuccess}: LoginButtonProperty) => {
    const [userData, setUserData] = useState<any>(null);
    const {t} = useTranslation()
    const {isLoggedIn, getHeaders, loginPopup, logout, getActiveAccount} = useAuth();


    const fetchUserData = useCallback(async () => {
        if (!isLoggedIn()) {
            return
        }
        const headers: Record<string, string> = {
            ...await getHeaders(),
            "Content-Type": "application/json",
        };

        const user = await fetch("/api/users/me/", {
            method: "GET",
            headers: headers
        })
        if (user.ok) {
            const userJson = await user.json()
            setUserData(userJson)
            onAuthenticationSuccess()
        } else {
            setUserData(null)
            const errorText = await user.text();
            console.log(errorText)
            onAuthenticationError(`${errorText} (http status: ${user.status})`)
        }
    }, [onAuthenticationError, onAuthenticationSuccess, getHeaders, isLoggedIn])

    useEffect(() => {
        if (isLoggedIn()) {
            fetchUserData()
        }
    }, [isLoggedIn, fetchUserData])


    const handleLoginPopup = useCallback(async () => {
        await loginPopup()
        await fetchUserData()
    }, [fetchUserData, loginPopup])

    const handleLogoutPopup = useCallback(async () => {
        await logout()
        setUserData(null)
    }, [logout])

    const logoutText =  userData != null ?
        `Logout ${userData.name}` :
        `Logout\n${getActiveAccount()?.name}`


    return (
        <div className={"space-y-4"}>
            {
                userData != null ?
                    <div className={"flex justify-start"}>
                        <img src={`/assets/icons/organization/${userData.organization}`} alt={t(`organization name: ${userData.organization}`)}/>
                    </div> : <></>
            }
            <ActionButton
                className={"text-left flex-row justify-start"}
                onLoad={fetchUserData}
                onClick={isLoggedIn() ? handleLogoutPopup : handleLoginPopup}>
                <span className={"pr-2"}>
                    <svg
                        className="w-4 h-4 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24">
                        <path fillRule="evenodd"
                              d="M12 20a8 8 0 0 1-5-1.8v-.6c0-1.8 1.5-3.3 3.3-3.3h3.4c1.8 0 3.3 1.5 3.3 3.3v.6a8 8 0 0 1-5 1.8ZM2 12a10 10 0 1 1 10 10A10 10 0 0 1 2 12Zm10-5a3.3 3.3 0 0 0-3.3 3.3c0 1.7 1.5 3.2 3.3 3.2 1.8 0 3.3-1.5 3.3-3.3C15.3 8.6 13.8 7 12 7Z"
                              clipRule="evenodd"/>
                    </svg>
                </span>
                {isLoggedIn() ? logoutText : "Login"}
            </ActionButton>
        </div>
    );
};

interface LoginButtonProperty {
    onAuthenticationError: (errorText: string) => void
    onAuthenticationSuccess: () => void
}
