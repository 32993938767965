import {CaseHistory} from "./CaseHistory";
import {Discussion} from "./Discussion";
import {CaseMeta} from "./CaseMeta";
import React, {useCallback, useEffect, useState} from "react";
import {CaseMessage, CaseMessageRole, CaseMessageType} from "./Conversation";
import {useTranslation} from "react-i18next";
import {CaseInfo, CaseMessageResponse, EscalationResponse, CaseMetaInfo, SupportCase, IssueTracker} from "./model";
import {onlyIfUnchanged, toRole} from "./utils";
import {ErrorViewer, useErrorReporter} from "./ErrorViewer";
import {LoginButton} from "../components/LoginButton";
import {TitleBox} from "./TitleBox";
import {ActionButton} from "./ActionButton";
import {useAuth} from "../auth/AuthHook";
import {useNavigate, useParams} from "react-router-dom";

interface SupportFrameProps {
}

interface BackendMessageResponse {
    text: string
    party: string
    role: string
    user_id?: string
    time_to_respond?: string
    attachment_id?: string
    creation_time?: string
    closure_time?: string

}

export function SupportFrame({}: SupportFrameProps) {
    const {caseId} = useParams();

    function emptyCase(): CaseMetaInfo {
        return {
            "title": "",
            "severity": "Normal",
            "status": "Open"
        };
    }

    const [supportCase, setSupportCase] = useState<SupportCase>({
        case_id: null,
        ...emptyCase(),
        messages: []
    })
    const [cases, setCases] = useState<CaseInfo[]>([])
    const {t} = useTranslation()
    const {isLoggedIn, getHeaders, getUserId} = useAuth()
    const reporter = useErrorReporter()
    const [isInitialized, setInitialized] = useState(false)
    const [releaseInfo, setReleaseInfo] = useState("")
    const [issueTrackerName, setIssueTrackerName] = useState("")
    const [waitingForResponse, setWaitingForResponse] = useState(false)
    const [initiationTimeStamp, setInitiationTimeStamp] = useState(0)
    const navigate = useNavigate()

    const setSupportCaseIfUnchanged = useCallback((f: (c: SupportCase) => SupportCase) => {
        setSupportCase(onlyIfUnchanged(supportCase?.case_id, f))
    }, [supportCase])

    const doFetchHistory = useCallback(async () => {
        try {
            const response = await fetch(`/api/cases/`, {
                method: "GET",
                headers: await getHeaders()
            });

            if (!response.ok || response.status > 299) {
                const errorMsg = await response.text()
                reporter(errorMsg)
                return
            }
            setCases(await response.json())
            setInitialized(true)
        } catch (e) {
            reporter(`${e}`);
        }

    }, [getHeaders, reporter])

    const fetchHistory = useCallback(async () => {
        if (isLoggedIn()) {
            await doFetchHistory();
        }
    }, [isLoggedIn, doFetchHistory])

    const fetchBuildInfo = useCallback(async () => {
        try {
            if (isLoggedIn()) {
                const resp = await fetch("/api/server/build_info/", {
                    headers: {
                        ...await getHeaders(),
                        "Content-Type": "application/json",
                    },
                })
                if (!resp.ok) {
                    reporter(`Error received while fetching build info: ${resp.status} - ${await resp.text()}`)
                    return
                }
                const id_resp = await resp.json();
                const rel = id_resp["release"]
                if (rel != undefined) {
                    setReleaseInfo(rel)
                }
            }
        } catch (e) {
            reporter(`${e}`);
        }
    }, [isLoggedIn, getHeaders, reporter])

    const fetchIssueTrackerName = useCallback(async () => {
        try {
            if (isLoggedIn()) {
                const resp = await fetch("/api/organisations/self/issue_tracker/", {
                    headers: {
                        ...await getHeaders(),
                        "Content-Type": "application/json",
                    },
                })
                if (!resp.ok) {
                    reporter(`Error received while fetching issue tracker name: ${resp.status} - ${await resp.text()}`)
                    return
                }
                const issueTracker_resp = await resp.json();
                const issueTrackerName = issueTracker_resp["issueTrackerName"]
                if (issueTrackerName != undefined) {
                    setIssueTrackerName(issueTrackerName)
                }
            }
        } catch (e) {
            reporter(`${e}`);
        }
    }, [isLoggedIn, getHeaders, reporter])


    useEffect(() => {
        fetchBuildInfo()
        fetchIssueTrackerName()
        fetchHistory()
    }, [fetchBuildInfo, fetchHistory, fetchIssueTrackerName])

    const updateOrCreate = useCallback(async (isNew: boolean, caseId: string | null, metaInfo: CaseMetaInfo) => {
        try {
            const resp = await fetch("/api/cases/" + (isNew ? "" : caseId), {
                method: "POST",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(metaInfo)
            })
            if (!resp.ok) {
                reporter(`Error received while updating case: ${resp.status} - ${await resp.text()}`)
                return null;
            }
            const resp_json = await resp.json();
            fetchHistory()
            return resp_json
        } catch (e) {
            reporter(`${e}`);
            return null;
        }
    }, [fetchHistory, getHeaders, reporter])

    const handleMetaInfoUpdate = useCallback(async (caseId: string | null, metaInfo: CaseMetaInfo) => {
        const meta = await updateOrCreate(caseId == null, caseId, metaInfo);
        if (meta !== null) {
            setSupportCase({
                ...meta,
                "messages": supportCase.messages
            })
            navigate(`/cases/${meta.case_id}`)
        }
    }, [supportCase, updateOrCreate])

    const handleNewCaseInitiation = useCallback(() => {
        setSupportCase({
            case_id: null,
            ...emptyCase(),
            messages: []
        })
        setInitiationTimeStamp(Date.now())
    }, [])

    const handleCaseSelected = useCallback(async (caseId: string) => {
        navigate(`/cases/${caseId}`)
    }, [navigate])

    const fetchCase = useCallback(async () => {
        try {
            const resp = await fetch("/api/cases/" + caseId, {
                method: "GET",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                }
            })
            if (!resp.ok) {
                reporter(await resp.text())
                return
            }
            const caseInfo = await resp.json();
            const messages = caseInfo.messages as BackendMessageResponse[];
            const filteredMessages = messages
                .map(m => {
                    return {
                        "text": m.text,
                        "messageType": m.party == "user" ? CaseMessageType.USER_MESSAGE : CaseMessageType.MESSAGE_RESPONSE,
                        "messageRole": toRole(m.role),
                        "requestedBy": m?.user_id,
                        "timeToRespond": m?.time_to_respond === undefined ? undefined : +m?.time_to_respond,
                        "creationTime": m?.creation_time,
                        "attachment_id": m?.attachment_id
                    } as CaseMessage
                })
                .filter(m => m.messageRole !== CaseMessageRole.SUMMARY && m.messageRole !== CaseMessageRole.ESCALATION)
            setSupportCase({
                ...caseInfo,
                "messages": filteredMessages
            })
        } catch (e) {
            reporter(`${e}`)
        }
    }, [getHeaders, reporter, caseId])

    useEffect(() => {
        if (caseId !== undefined && caseId !== null) {
            fetchCase()
        }
    }, [caseId, fetchCase])

    // takes a function that converts a support case
    const addNewMessage = useCallback((msg: CaseMessage) => {
        setSupportCaseIfUnchanged((c) => {
            return {
                ...c,
                messages: [
                    ...c.messages,
                    msg
                ]
            }
        })
    }, [setSupportCaseIfUnchanged])

    const addIssueTrackerDetails = useCallback((incoming_issue_tracker: IssueTracker) => {
        setSupportCaseIfUnchanged((c) => {
            return {
                ...c,
                issue_tracker: incoming_issue_tracker
            }
        })
    }, [setSupportCaseIfUnchanged])


    const removeLastMessage = useCallback((msg: CaseMessage) => {
        setSupportCaseIfUnchanged((c) => {
            const lastMessage = c.messages[c.messages.length - 1]
            const messages = (lastMessage.messageType === msg.messageType && lastMessage.text === msg.text) ?
                c.messages.slice(0, c.messages.length - 1) : c.messages
            return {
                ...c,
                messages: messages
            }
        })
    }, [setSupportCaseIfUnchanged])

    const handleNewQuestion = useCallback(async (input: string, file_id?: string): Promise<boolean> => {
        if (supportCase.case_id == null) {
            return false
        }
        const msg = {
            text: input,
            messageType: CaseMessageType.USER_MESSAGE,
            messageRole: CaseMessageRole.REQUEST,
            requestedBy: getUserId(),
            attachment_id: file_id
        };
        setWaitingForResponse(true)
        try {
            addNewMessage(msg);
            const response = await fetch(`/api/cases/${supportCase.case_id}/ask`, {
                method: "POST",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "message": input,
                    "attachment_id": file_id
                })
            });

            if (!response.ok || response.status > 299) {
                reporter(`Error receiving response for user message ${response.status} - ${await response.text()}`);
                removeLastMessage(msg);
                return false
            }
            const parsedResponse: CaseMessageResponse = await response.json();
            addNewMessage({
                text: parsedResponse.text,
                messageType: CaseMessageType.MESSAGE_RESPONSE,
                messageRole: CaseMessageRole.RESPONSE,
                timeToRespond: parsedResponse.time_to_respond
            });
            return true
        } catch (e) {
            reporter(`${e}`);
            removeLastMessage(msg);
            return false
        } finally {
            setWaitingForResponse(false)
        }

    }, [removeLastMessage, supportCase, getHeaders, getUserId, addNewMessage, reporter])

    const handleEscalation = useCallback(async () => {
        try {
            setWaitingForResponse(true)
            const response = await fetch(`/api/cases/${supportCase.case_id}/escalate`, {
                method: "POST",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok || response.status > 299) {
                reporter("Escalation error: " + await response.text())
                return
            }
            const parsedResponse: EscalationResponse = await response.json();
            addNewMessage({
                text: parsedResponse.escalation_message.text,
                messageType: CaseMessageType.MESSAGE_RESPONSE,
                messageRole: CaseMessageRole.ESCALATION_RESPONSE,
                timeToRespond: parsedResponse.escalation_message.time_to_respond
            });
            addIssueTrackerDetails(parsedResponse.issue_tracker)
            setSupportCaseIfUnchanged((c) => {
                if (issueTrackerName !== "notracker") {
                    return {
                        ...c,
                        status: "Escalated"
                    }
                }
                return c
            })
            await fetchHistory()
        } catch (e) {
            reporter(`${e}`);
        } finally {
            setWaitingForResponse(false)
        }
    }, [supportCase, getHeaders, addNewMessage, reporter, addIssueTrackerDetails, fetchHistory])

    const handleClosure = useCallback(async () => {
        try {
            setWaitingForResponse(true)

            const response = await fetch(`/api/cases/${supportCase.case_id}/close`, {
                method: "POST",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok || response.status > 299) {
                reporter("Error receiving response for closure")
                return
            }
            setSupportCaseIfUnchanged((c) => {
                return {
                    ...c,
                    status: "Closed"
                }
            })
            await fetchHistory()
        } catch (e) {
            reporter(`${e}`);
        } finally {
            setWaitingForResponse(false)
        }
    }, [supportCase, fetchHistory, getHeaders, reporter])

    const handleDelete = useCallback(async () => {
        try {
            setWaitingForResponse(true)

            const response = await fetch(`/api/cases/${supportCase.case_id}`, {
                method: "DELETE",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok || response.status > 299) {
                const errorMsg = await response.text()
                reporter(`Error receiving response for deletion: ${errorMsg}`)
                return
            }
            setSupportCaseIfUnchanged((c) => {
                return {
                    case_id: null,
                    ...emptyCase(),
                    messages: []
                }
            })
            await fetchHistory()
            navigate("/")
            reporter(t("Case deleted permanently"))
        } catch (e) {
            reporter(`${e}`);
        } finally {
            setWaitingForResponse(false)
        }
    }, [supportCase, fetchHistory, getHeaders, reporter, navigate])

    return (
        <div className={`flex flex-row justify-evenly grow overflow-y-auto`}>
            <div className="flex flex-col justify-start bg-black basis-[15%]">
                <TitleBox lineHidden={true}>
                    <div className="text-l text-blue-enov text-center font-bold">
                        Nestor.ai
                    </div>
                    <div className="text-l text-white text-center">
                        {t("Kubernetes support assistant")}
                    </div>
                    <div className={`${releaseInfo == "" ? "hidden" : ""} text-xs`}>
                        {releaseInfo}
                    </div>
                </TitleBox>
                <CaseHistory
                    onItemSelected={handleCaseSelected}
                    selectedCaseId={caseId ?? null}
                    initialized={isInitialized}
                    cases={cases}
                />
            </div>
            <div className="flex flex-col justify-between grow bg-gray-really basis-[70%]">
                <TitleBox lineHidden={false}>
                    {supportCase.title == "" ? (
                            <div className="text-2xl text-white text-center ">
                                {t("There are no preceding messages to display")}
                            </div>
                        )
                        : (
                            <div className="text-2xl text-white text-center">
                                {supportCase.title}
                            </div>

                        )}
                </TitleBox>
                <Discussion
                    supportCase={supportCase}
                    key={supportCase.case_id}
                    onNewQuestion={(input, file_id) => handleNewQuestion(input, file_id)}
                    waitingForResponse={waitingForResponse}
                />
            </div>
            <div className="py-4 flex flex-col justify-between bg-black basis-[15%]">
                <div className={"flex flex-col justify-start"}>
                    <div className="px-6 py-2">
                        <LoginButton
                            onAuthenticationError={reporter}
                            onAuthenticationSuccess={async () => {}}
                        />
                    </div>
                    <div className="px-6 py-2">
                        <ActionButton
                            className="text-left flex-row justify-start"
                            onClick={handleNewCaseInitiation}>
                            {t("Create new case ...")}
                        </ActionButton>
                    </div>

                </div>

                <div className="flex flex-col justify-start pt-12">
                    <CaseMeta
                        caseId={supportCase.case_id}
                        key={supportCase.case_id}
                        caseMetaInfo={supportCase}
                        onSubmitChanges={handleMetaInfoUpdate}
                        titleHidden={true}
                        initTimeStamp={initiationTimeStamp}
                        issueTrackerName={issueTrackerName}
                    />
                    <ErrorViewer/>
                    <div className="px-6 py-2">
                        {issueTrackerName === "notracker" || supportCase?.issue_tracker?.issue_key == null ?
                            (<ActionButton
                                className={`text-left flex-row justify-start disabled:bg-inherit`}
                                disabled={
                                    supportCase.messages.length == 0 ||
                                    supportCase.status.toLowerCase() === "closed" ||
                                    supportCase.status === "Escalated" ||
                                    (issueTrackerName !== "notracker" &&
                                        (supportCase.issue_tracker == undefined ||
                                            supportCase.issue_tracker.project_key == undefined || supportCase.issue_tracker.project_key === ""))
                                }
                                onClick={handleEscalation}>
                                {t("Escalate")}
                            </ActionButton>)
                            :
                            (<ActionButton
                                className={`text-left flex-row justify-start disabled:bg-inherit`}
                                hidden={supportCase.status !== "Escalated"}
                                onClick={() => window.open(supportCase.issue_tracker?.issue_url, "_blank")}>
                                {t("Open in " + issueTrackerName)}
                            </ActionButton>)
                        }
                    </div>
                    <div className="px-6 py-2">
                        <ActionButton
                            className="text-left flex-row justify-start"
                            onClick={handleClosure}
                            disabled={supportCase.case_id == null || supportCase.status.toLowerCase() === "closed"}>
                            {t("Close case")}
                        </ActionButton>
                    </div>
                    <div className="px-6 py-2">
                        <ActionButton
                            className="text-left flex-row justify-start"
                            onClick={handleDelete}
                            disabled={supportCase.case_id == null}>
                            {t("Delete case")}
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
