import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {CaseInfo} from "./model";
import {formatTimeStamp} from "./utils";
import {Spinner, Tooltip} from 'flowbite-react';
import {useAuth} from "../auth/AuthHook";

interface CaseHistoryProps {
    onItemSelected: (caseId: string) => void,
    selectedCaseId: string | null
    cases: CaseInfo[]
    initialized: boolean
}

export function CaseHistory(props: CaseHistoryProps) {
    const {isLoggedIn} = useAuth()
    const { t } = useTranslation();
    const [isCollapsed, setCollapsed] = useState(true)

    const itemSelectHandler = (id: string) => {
        props.onItemSelected(id)
    }


    if (!isLoggedIn()) {
        return (
            <div>
                <div className="p-4">
                    {t("You need to log in to see past cases")}
                </div>
            </div>
        )
    }

    function asRow(i: CaseInfo, index: number) {
        if (isCollapsed) {
            const bgColor = i.case_id == props.selectedCaseId ? "bg-gray-really" : ""
            return (
                <tr key={index} className={`hover:bg-gray-really rounded p-4 text-s ${bgColor} `}>
                    <td
                        className={`truncate max-w-xs dark:text-white hover:cursor-pointer py-2 rounded`}
                        onClick={() => itemSelectHandler(i.case_id ?? "")}
                    >
                        {i.title}
                    </td>
                </tr>
            )
        } else {
            const stripedBgColor = index % 2 == 1 ? "bg-neutral-950" : ""
            const bgColor = i.case_id == props.selectedCaseId ? "bg-gray-really" : stripedBgColor
            return (
                <tr key={index} className={`hover:bg-gray-really rounded p-4 text-s ${bgColor}`}>
                    <td
                        className={`truncate max-w-xs dark:text-white hover:cursor-pointer py-2 pr-2 rounded-l`}
                        onClick={() => itemSelectHandler(i.case_id ?? "")}
                    >
                        {i.title}
                    </td>
                    <td className={`py-2 pr-2`}>
                        {
                            i.severity == "" ?
                                <div className="w-4 h-4">{"-"}</div> :
                                <Tooltip content={i.severity} className="text-gray-300 text-xs">
                                    <img src={`/assets/icons/severity/${i.severity.toLowerCase()}.svg`}
                                         alt={i.severity.substring(0, 1)} className="w-4 h-4"/>
                                </Tooltip>

                        }
                    </td>
                    <td
                        className={`py-2 pr-2`}
                        onClick={() => itemSelectHandler(i.case_id ?? "")}
                    >
                        <Tooltip content={i.status.charAt(0).toUpperCase() + i.status.slice(1)} className="text-gray-300 text-xs">
                            {i.status == "" ?
                                <div className="w-4 h-4">{"-"}</div> :
                                <img src={`/assets/icons/status/${i.status.toLowerCase()}.svg`}
                                     alt={i.status.toLowerCase().substring(0, 1)} className="w-4 h-4"/>
                            }
                        </Tooltip>
                    </td>
                    {
                        i.creation_time == undefined ? <></>:
                            <td className={`truncate max-w-xs dark:text-white hover:cursor-pointer py-2 pr-2 rounded-r text-xs`}>
                                {formatTimeStamp(i.creation_time)}
                            </td>
                    }
                </tr>
            )
        }
    }

    return (
        <div className="flex flex-col justify-start bg-black overflow-y-auto">
            <div className="flex flex-row justify-between overflow-y-auto">
                <nav className="flex flex-col grow overflow-y-auto">
                    {props.initialized ? (
                        <div className={`flex flex-col overflow-y-auto px-6 w-full`}>
                            <table className="text-left text-s">
                                <thead>
                                    {
                                        isCollapsed ? (
                                            <tr className="p-4 text-xs text-neutral-500">
                                                <th scope="col" className="py-4 pr-4">{t("Support Case Title Header")}</th>
                                            </tr>
                                        ) : (
                                            <tr className="p-4 text-xs text-neutral-500">
                                                <th scope="col" className="py-4 pr-4">{t("Support Case Title Header")}</th>
                                                <th scope="col" className="py-4 pr-4">{t("Case severity")}</th>
                                                <th scope="col" className="py-4 pr-4">{t("Case status")}</th>
                                                <th scope="col" className="py-4 pr-4">{t("Case creation time header")}</th>
                                            </tr>
                                        )
                                    }
                                </thead>
                                <tbody>
                                    {
                                        props.cases.map(asRow)
                                    }
                                </tbody>
                            </table>
                        </div>)
                    :
                        (<div className={`flex flex-col overflow-y-auto w-full gap-2 p-6`}>
                        <Spinner
                            color="info"
                            aria-label="History waiting spinner"
                            size="lg"
                            className="animate-spin"

                        />
                        </div>)
                    }
                </nav>
                {props.initialized ? (
                    <div className={`flex items-center h-full size-fit`}>
                        <button className="text-xs size-fit bg-transparent border-none p-0"
                                onClick={() => setCollapsed((current) => !current)}>
                            {isCollapsed ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-4 h-32 scale-y-150">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-4 h-32 scale-y-150">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
                                </svg>
                            )}
                        </button>
                    </div>
                ):""}
            </div>


        </div>

    )

}