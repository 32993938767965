import ReactDOM from "react-dom/client";
import React from "react";
import {AccountInfo, EventType, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./authConfig";
import {MsalProvider} from "@azure/msal-react";
import {SupportApp} from "./support/SupportApp";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./assets/locales/en.json"
import de from "./assets/locales/de.json"

import "./tailwind.css";

let options = {
    resources: {
        en: {
            translation: en,
        },
        de: {
            translation: de,
        },
    },
    supportedLngs: ["en", "de"],
    fallbackLng: 'en',
    debug: true,

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    }
};
await i18n
    .use(initReactI18next)
    .init(options)

var msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount());
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const account = event.payload as AccountInfo;
        msalInstance.setActiveAccount(account);
    }
});

await msalInstance.initialize()



type CaseCreationResponse = {
    caseId: string
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <SupportApp/>
        </MsalProvider>
    </React.StrictMode>
);
